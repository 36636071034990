.header {
  position: relative;
  overflow-x: hidden;
  background-color: #0a130e;
  min-height: 100vh;
  background-image: url("../images/image_header.png");
  background-repeat: no-repeat;
  background-size: 170%;
  background-position: 10% 20%;
}

.header__menu--container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px 8px;
  background-color: rgba(10, 19, 14, 0.3);
  z-index: 2;
}

.header__logo {
  width: 100px;
  margin: 12px;
  z-index: 2;
}

.header__medias {
  display: flex;
  align-items: center;
  margin: 0 8px 0 auto;
  z-index: 2;
}

.fa {
  font-size: 24px;
  color: #fff;
  margin: 0 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.fa:hover {
  color: #e67918;
}

.header__desc--container {
  position: absolute;
  bottom: 20%;
  right: 0;
  font-family: "Anton", sans-serif;
  background-color: rgba(10, 19, 14, 0.3);
  max-width: 600px;
  padding: 4px;
}

.header__desc--title {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  letter-spacing: 1px;
}

.header__desc--text {
  color: #fff;
  margin: 18px 18px 18px 40px;
  font-size: 16px;
}

.stal {
  color: #e67918;
}

.header__scroll {
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  animation: scrollBtn 2s infinite;
}

.header__scroll:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.header__scroll:focus {
  outline: none;
  background-color: transparent;
}

.header__scroll--btn {
  width: 40px;
  transform: translateX(-50%);
}

@media (min-width: 640px) {
  .header__desc--text {
    text-align: end;
    padding: 0 40px;
  }

  .header__desc--container {
    bottom: 40%;
    right: 100px;
  }

  .header__desc--title {
    font-size: 40px;
  }
  .header__desc--text {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .header__menu--container {
    height: 80px;
    background-color: transparent;
  }

  .header__desc--container {
    background-color: transparent;
  }
}

@media (min-width: 1024px) {
  .header {
    background-size: 100%;
    background-position: 50% 50%;
  }

  .header__desc--title {
    font-size: 48px;
  }
}

@media (min-width: 1200px) {
  .header__logo {
    margin-left: 32px;
  }

  .header__medias {
    margin-right: 32px;
  }
  .header__desc--title {
    font-size: 56px;
  }
}

@media (min-width: 1400px) {
  .header__desc--title {
    font-size: 64px;
  }
}

@media (orientation: landscape) and (min-width: 640px) {
  .header__desc--container {
    bottom: 10%;
  }
  .header__desc--title {
    font-size: 24px;
  }
  .header__scroll {
    display: none;
  }
}

@media (orientation: landscape) and (min-width: 992px) and (min-height: 600px) {
  .header__desc--container {
    bottom: 40%;
  }
  .header__desc--title {
    font-size: 40px;
  }
  .header__scroll {
    display: block;
  }
}

@media (orientation: landscape) and (min-width: 1024px) and (min-height: 600px) {
  .header__desc--container {
    bottom: 30%;
  }
}

@keyframes scrollBtn {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
