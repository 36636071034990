.history {
  width: 100%;
}

.about__company {
  font-family: "Anton", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 30px;
  padding: 40px 20px 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.history--container {
  max-width: 1400px;
  margin: 0 auto;
  background-image: url("../images/Vector.png");
  background-size: contain;
  padding-bottom: 20px;
}

.history__title {
  font-family: "Anton", sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding: 4px 20px 16px;
}

.history__desc--main {
  width: 100%;
  height: 100%;
  font-family: "Anton", sans-serif;
  padding: 12px 20px;
}

.history__date {
  font-family: "Anton", sans-serif;
  font-size: 20px;
  padding: 12px 20px;
}

.year {
  color: #e67918;
  font-size: 30px;
}

.history__desc--other {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  padding: 12px 20px 12px;
}

@media (min-width: 992px) {
  .about__company {
    font-size: 36px;
  }
  .history--container {
    display: flex;
    flex-wrap: wrap;
  }
  .history__title {
    width: 100%;
    font-size: 30px;
  }
  .history__column--container {
    display: flex;
    flex-direction: column;
    width: 50%;
    /* padding-left: 50px; */
  }
  .history__desc--main {
    font-size: 18px;
  }
  .history__date {
    padding-top: 16px;
    font-size: 24px;
  }
  .year {
    font-size: 36px;
  }
  .history__desc--other {
    width: 50%;
  }
}

@media (min-width: 1400px) {
  .about__company {
    font-size: 48px;
  }

  .history__title {
    font-size: 36px;
    padding-top: 16px;
  }
  .history__column--container {
    padding-top: 8px;
  }
  .history__desc--main {
    font-size: 20px;
  }
  .history__date {
    padding-top: 16px;
    font-size: 26px;
  }
  .year {
    font-size: 44px;
  }
  .history__desc--other {
    font-size: 18px;
  }
}
