.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #0a130e;
}

.contact__title {
  width: 100%;
  max-width: 1400px;
  padding: 30px 30px 20px 30px;
  margin: 0 auto;
  font-family: "Anton", sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
}

.form__desc {
  max-width: 500px;
  color: #fff;
  padding: 18px 32px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-align: center;
}

.form--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.input__wrapper {
  display: block;
  position: relative;
  margin: 25px 0;
}

.input__contact {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background-color: #dedede;
  border-radius: 5px;
  border: none;
  width: 250px;
  height: 30px;
  transition: 0.2s ease-in;
  padding: 0 10px;
}

.input__contact:focus {
  outline: none;
  border-bottom: 3px solid #e67918;
}

.input__label {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: -20px;
  left: 0;
  transition: 0.2s ease-in;
}

.input__contact:placeholder-shown + .input__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

.textarea__contact {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background-color: #dedede;
  border-radius: 5px;
  border: none;
  width: 250px;
  height: 150px;
  transition: 0.2s ease-in;
  padding: 10px;
}

.textarea__contact:focus {
  outline: none;
  border-bottom: 3px solid #e67918;
}

.button__contact {
  width: 150px;
  height: 30px;
  background-color: #e67918;
  color: #fff;
  margin-top: 10px;
  border: none;
  font-family: "Open sans", sans-serif;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in;
  margin-left: 40%;
}

.button__contact:hover {
  background-color: #a55610;
}

.button__contact:disabled {
  cursor: default;
  opacity: 0.7;
  background-color: #aaa;
}

.contact--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.contact__single,
.contact__adres {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 12px;
  color: #fff;
  width: 70%;
}

.contact__name {
  font-family: "Anton", sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  padding: 10px 0;
}

.contact__surname {
  color: #e67918;
}

.contact__number {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  padding-bottom: 10px;
  color: #fff;
  text-decoration: none;
}

.contact__adres {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.contact__mail {
  color: #fff;
  text-decoration: none;
}

.contact__name-company,
.contact__street,
.contact__mail {
  padding-bottom: 10px;
}

@media (min-width: 640px) {
  .input__contact {
    width: 350px;
  }
  .textarea__contact {
    width: 350px;
  }
  .button__contact {
    width: 200px;
    margin-left: 42%;
  }
}

@media (min-width: 992px) {
  .form--container {
    width: 50%;
  }
  .contact--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 20px;
  }
}

@media (min-width: 1400px) {
  .contact__title {
    font-size: 40px;
  }

  .input__contact {
    width: 400px;
  }
  .textarea__contact {
    width: 400px;
    height: 300px;
  }
  .button__contact {
    width: 230px;
    margin-left: 42%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 35px;
  }
}

@media (min-width: 1600px) {
  .input__contact {
    width: 500px;
  }
  .textarea__contact {
    width: 500px;
  }
  .button__contact {
    width: 250px;
    margin-left: 50%;
  }
  .form--container {
    width: 40%;
  }
  .form__desc {
    padding: 20px 0px;
  }
  .contact--container {
    width: 40%;
  }
}
