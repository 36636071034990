.ownOffer--container {
  background-color: #0a130e;
  background-image: url("../images/zdjecie-plany.png");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position-x: right;
  background-position-y: 60%;
}

.ownOffer__title {
  max-width: 1400px;
  color: #fff;
  padding: 32px 18px 24px;
  text-align: left;
  letter-spacing: 1px;
  font-family: "Anton", sans-serif;
  font-size: 28px;
  font-weight: 500;
}

.ownOffer__desc--container {
  padding: 18px;
  max-width: 1024px;
}

.ownOffer__subtitle {
  max-width: 1400px;
  color: #fff;
  font-family: "Anton", sans-serif;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 18px 0;
}

.ownIdea {
  color: #e67918;
}

.ownOffer__desc {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-weight: 300;
  margin-top: 10px;
}

.ownOffer__howWorks {
  font-family: "Anton", sans-serif;
  color: #e67918;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  margin-top: 40px;
}

.individualOffer--container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px;
  background-color: #0a130e;
}

.individualOffer__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 18px auto;
}

.individualOffer__img {
  width: 100px;
  height: 100px;
}

.individualOffer__desc {
  font-family: "Anton", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  margin: 8px;
}

.number {
  color: #e67918;
}

@media (min-width: 640px) {
  .ownOffer__title {
    font-size: 32px;
  }
  .ownOffer__subtitle {
    font-size: 24px;
  }
  .ownOffer__desc {
    font-size: 18px;
  }
  .ownOffer__howWorks {
    font-size: 32px;
  }
}

@media (min-width: 1024px) {
  .ownOffer--container {
    padding: 0 40px;
  }

  .ownOffer__desc--container {
    padding: 24px;
  }
  .ownOffer__title {
    font-size: 34px;
    padding: 40px 8px;
  }
  .ownOffer__subtitle {
    font-size: 24px;
  }
  .ownOffer__howWorks {
    font-size: 34px;
    text-align: left;
  }
  .individualOffer__img {
    width: 150px;
    height: 150px;
  }
  .individualOffer__desc {
    font-size: 24px;
    margin-top: 18px;
  }
}

@media (min-width: 1400px) {
  .ownOffer__desc--container {
    padding: 32px;
  }
  .ownOffer__title {
    font-size: 40px;
  }
  .ownOffer__subtitle {
    font-size: 28px;
  }
  .ownOffer__howWorks {
    font-size: 40px;
    padding: 0 32px;
  }
}
