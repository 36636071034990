.footer {
  display: block;
  background-color: #0a130e;
  color: #777;
  text-align: right;
  font-size: 14px;
  padding: 30px 20px 10px 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
