@import url("https://fonts.googleapis.com/css2?family=Anton&family=Open+Sans:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-thumb {
  background: #e67918;
  border-radius: 18px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b15c12;
}
