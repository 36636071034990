.employers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.employers__title {
  font-family: "Anton", sans-serif;
  font-weight: 500;
  font-size: 30px;
  max-width: 1400px;
  padding: 8px 20px;
  width: 100%;
}

.employee--container {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
}

.employee__desc--container {
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
  align-items: center;
  margin-bottom: 20px;
}

.employee__img {
  max-width: 300px;
  margin: 16px;
  border-radius: 5px;
  box-shadow: 0 0 2px #ccc;
}

.employee__name {
  font-family: "Anton", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 24px;
  padding: 8px;
}

.employee__surname {
  color: #e67918;
}

.employee__exp {
  font-family: "Anton", sans-serif;
  font-size: 18px;
  padding: 8px;
}

.employee__permission {
  font-family: "Anton", sans-serif;
  font-size: 16px;
  padding: 8px;
  text-align: center;
}

.employee__desc {
  font-family: "Open sans", sans-serif;
  font-weight: 300;
  padding: 8px 0;
  max-width: 600px;
}

@media (min-width: 640px) {
  .employers {
    margin-bottom: 40px;
  }
  .employee--container {
    flex-direction: row;
  }
  .employee__img--second {
    order: 1;
  }
  .employee__desc--container {
    align-items: flex-start;
  }
}

@media (min-width: 1400px) {
  .employers__title {
    font-size: 40px;
  }
  .employee__name {
    font-size: 32px;
  }
  .employee__img {
    max-width: 350px;
    margin-right: 80px;
  }
  .employee__img--second {
    margin-right: 10px;
    margin-left: 80px;
  }
}
@media (min-width: 1600px) {
  .employee__img {
    margin-right: 180px;
  }
  .employee__img--second {
    margin-right: 10px;
    margin-left: 120px;
  }
}
