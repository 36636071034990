.menu__list {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  background: rgba(10, 19, 14, 0.9);
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
}

.show {
  transform: translateY(0);
}

.menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 3;
}

.menu-btn__burger {
  width: 30px;
  height: 5px;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 5px;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-12px);
}

.menu-btn__burger::after {
  transform: translateY(12px);
}

.menu-btn.open .menu-btn__burger {
  transform: translateX(-30px);
  background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(25px, -25px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(25px, 25px);
}

.menu__item {
  margin: 4px 0;
  padding: 4px 4px;
}

.menu__link {
  color: #e67918;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}
.menu__link:hover {
  color: #f7ad6d;
}

@media (min-width: 992px) {
  .menu-btn {
    display: none;
  }

  .menu__list {
    width: 100%;
    transform: translateY(0);
    max-height: 80px;
    flex-direction: row;
    justify-content: center;
  }

  .menu__item {
    margin: 4px 0;
    padding: 4px 12px;
  }
  .menu__link {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .menu__link {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .menu__item {
    padding: 4px 24px;
  }
  .menu__link {
    font-size: 24px;
  }
}
